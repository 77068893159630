import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RebarAuthService } from './core/rebarauth/rebar.auth.service';
import { ResourcesService } from './appservices/masters/master/resources.service';
import { AppConfigService } from './core/services/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'rebar-pwa';
  configData: any = null

  constructor(private rebarAuthService: RebarAuthService, private resourcesService: ResourcesService, private appConfig: AppConfigService) {
    this.configData = this.appConfig.getConfig();
  }
  resourceId: any;
  ngOnInit(): void {
    // force login on page load rather than on route change

    if (this.rebarAuthService.authenticationEnabled() && !this.rebarAuthService.IsUserAuthenticated())
    {
      this.rebarAuthService.login();

    }
    else {
      var clientSession = 'msal.' + this.configData.Web_Client_Id + '.idtoken';
      if (sessionStorage[clientSession] != null && sessionStorage[clientSession] != undefined && sessionStorage[clientSession] != '') {
        console.log('yesClientToken');
        console.log(sessionStorage[clientSession]);

        const helper = new JwtHelperService();
        const rawtoken = sessionStorage[clientSession];
         sessionStorage.setItem('id_token', rawtoken);
         const decodedToken = helper.decodeToken(rawtoken);
         const fields = decodedToken.preferred_username.split('@');
         const careerLevel = decodedToken.jobFamilyDescription;
         this.resourcesService.LoggedInUser=fields[0];
         sessionStorage.setItem('userName', fields[0]);
         sessionStorage.setItem('LoggedinUser', fields[0]);
         //sessionStorage.setItem('careerLevel',careerLevel);
         console.log('careerLevel ',careerLevel);
        //this.getResourceId(fields[0]);
      }
      else {
        console.log('noClientToken');
      }
      //this.getResourceId('aditya.pastula');
    }
  }
  //public async getResourceId(enterpriseId): Promise<any> {
  //  const resourceDetails = await this.resourcesService.getResourceId(enterpriseId);
  //  this.resourceId = resourceDetails[0].ResourceId;
  //  sessionStorage.setItem('resourceId', resourceDetails[0].ResourceId);
  //  sessionStorage.setItem('userName', enterpriseId);
  //  sessionStorage.setItem('LoggedinUser', enterpriseId);
  //}
}
