import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class LoadingHelperService {

  constructor() { }

  private _loading = new BehaviorSubject<boolean>(false);

  isLoading(): Observable<boolean> {
    return this._loading;
  }

  startLoader(): void {
    this._loading.next(true);
  }

  stopLoader(): void {
    this._loading.next(false);
  }
}

@Injectable({
  providedIn: 'root'
})
export class NotifyHelperService {

  messageAutoClosetimeOut: number;
  constructor(private toastrService: ToastrService) {
    this.messageAutoClosetimeOut = 5000; // In MilliSeconds
  }

  success(message) {
    this.toastrService.success(message, "Success", { timeOut: this.messageAutoClosetimeOut, extendedTimeOut: this.messageAutoClosetimeOut });
  }

  warning(message) {
    this.toastrService.warning(message, "Warning", { timeOut: this.messageAutoClosetimeOut, extendedTimeOut: this.messageAutoClosetimeOut });
  }

  error(message) {
    this.toastrService.error(message, "Error", { timeOut: this.messageAutoClosetimeOut, extendedTimeOut: this.messageAutoClosetimeOut });
  }

  info(message) {
    this.toastrService.info(message, "Warning", { timeOut: this.messageAutoClosetimeOut, extendedTimeOut: this.messageAutoClosetimeOut });
  }
}

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  loadingHelper: LoadingHelperService;
  notifyHelper: NotifyHelperService;

  constructor(private loadingHelperService: LoadingHelperService, private NotifyHelperService: NotifyHelperService) {
    this.loadingHelper = loadingHelperService;
    this.notifyHelper = NotifyHelperService;
  }
}

@Injectable({
  providedIn: 'root'
})
 export class PropertyService{
   constructor(){}
   private property = new BehaviorSubject<string>('');
  castProperty = this.property.asObservable();

  setProperty(newProperty) {
    this.property.next(newProperty);
  }

  private scanProperty = new BehaviorSubject<string>('');
  scanCastProperty = this.scanProperty.asObservable();
   
  scanSetProperty(scanNewProperty) {
    this.scanProperty.next(scanNewProperty);
  }
 }
