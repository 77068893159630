import { Injectable } from '@angular/core';

import { AppConfigService } from '../services/app-config.service';

import { RebarAuthModule } from './rebar.auth.module';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: RebarAuthModule,
})
export class RebarAuthService {
  configData: any = null;

  constructor(private auth: MsalService, config: AppConfigService) {
    this.configData = config.getConfig();
    this.auth.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

      console.log('Redirect Success: ', response.accessToken);
    });
  }

  public IsUserAuthenticated(): boolean {
    return environment.providers !== 'mock' && !!this.auth.getAccount();
  }

  public authenticationEnabled(): boolean {
    return environment.providers !== 'mock';
  }

  public login(): void {
    if (environment.providers !== 'mock') {
      this.auth.loginRedirect();
    }
  }
}
