<div class="row p-0 align-items-center ">
    <div class="col-9">
        <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" class="custom-message"
            [class]="options.messageClass" [attr.aria-label]="message">
            {{ message }}
        </div>
    </div>
    <div class="col-3 text-right">
        <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-sm toast-close-button px-1 py-0">
            &times;
        </a>
    </div>
</div>