import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home',
    loadChildren: () => import('./feature/home/home.module').then(m => m.HomeModule)
    // canActivate: [REBAR_AUTH_GUARD]
  },
  {
    path: 'checkin',
    loadChildren: () => import('./feature/seat-reservation/seat-reservation.module').then(m => m.SeatReservationModule)
    // canActivate: [REBAR_AUTH_GUARD]
  }
     /* {
      path: 'home',
      loadChildren: './feature/home/home.module#HomeModule',
      canActivate: [REBAR_AUTH_GUARD]
    }*/
    ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
