import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../../appservices/common/helper.service';

@Injectable()
export class BaseService {
    public errorHandler: CustomErrorHandlerService;

    constructor(public http: HttpClient, public helperService: HelperService) {
        this.errorHandler = new CustomErrorHandlerService();
    }

  get(url: string): Observable<any> {
    this.helperService.loadingHelper.startLoader();
    console.log(url);
    return this.http.get(url).pipe(map((res: Response) => {
      console.log(url);
      return this.handleResponse(res)
    }),
      catchError((error) => { console.log(error); return (error) }
      ),
      finalize(() => {
        this.helperService.loadingHelper.stopLoader();
      })
    )
  }

    getcached(url: string): Observable<any> {
        this.helperService.loadingHelper.startLoader();
        console.log(url);
      return this.http.get(url, { observe: 'response' }).pipe(map((response) => {
            return this.handleResponse(response);
      }), catchError((error: Response) => (this.errorHandler.tryParseError(error)))
        , finalize(() => {
                this.helperService.loadingHelper.stopLoader();
            })
      )
    }

    post(url: string, postBody: any, options?: any): Observable<any> {
        this.helperService.loadingHelper.startLoader();
        console.log(url);
        if (options) {
            return this.http.post(url, postBody, options)
              .pipe(map((res: any) => {
                    return this.handleResponse(res);
              })
                , catchError((error: Response) => throwError(error))
                  ,finalize(() => {
                    this.helperService.loadingHelper.stopLoader();
                })
          )
        } else {
            return this.http.post(url, postBody)
              .pipe(map((res: Response) => {
                    return this.handleResponse(res);
              })
                , catchError((error: Response) => throwError(error))
                , finalize(() => {
                    this.helperService.loadingHelper.stopLoader();
                })
              )
        }
    }

    delete(url: string, postBody: any): Observable<any> {
        this.helperService.loadingHelper.startLoader();
      return this.http.delete(url).pipe(map((res: Response) => {
        return this.handleResponse(res);
      }), catchError((error: Response) => throwError(error))
        , finalize(() => {
                this.helperService.loadingHelper.stopLoader();
            })
        )
    }

    put(url: string, putData: string): Observable<any> {
        this.helperService.loadingHelper.startLoader();
      return this.http.put(url, putData).pipe(map((res: Response) => {
        return this.handleResponse(res);
      }), catchError((error: Response) => throwError(error))
        , finalize(() => {
                this.helperService.loadingHelper.stopLoader();
            })
        )
    }

    upload(url: string, file: File): Observable<any> {
        const formData: FormData = new FormData();
        if (file) {
            formData.append('files', file, file.name);
        }
        return this.post(url, formData);
    }

    formUrlParam(url: string, data: any): string {
        let queryString: string = '';
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (!queryString) {
                    queryString = `?${key}=${data[key]}`;
                } else {
                    queryString += `&${key}=${data[key]}`;
                }
            }
        }
        return url + queryString;
    }

    handleResponse(res: any): ServerResponse {
        return res;
    }
}

interface ServerResponse {
    data: any;
    error: string[];
    message: string;
    isSuccess: boolean;
}

class CustomErrorHandlerService {
    tryParseError(error: Response): any {
        try {
            return error.json();
        } catch (ex) {
            try {
                return error;
            } catch (ex) {
                return error.toString();
            }
        }
    }
}
