interface IUpdateSelfDeclarationVaxStatus {
    haveVaxSelfDeclaration: number;
    welcomeMsg: string;
    welcomeMsgErrorCode: number;
  }

  export class UpdateSelfDeclarationVaxStatus implements IUpdateSelfDeclarationVaxStatus {
    haveVaxSelfDeclaration: number;
    welcomeMsg: string;
    welcomeMsgErrorCode: number;
       
    constructor(haveVaxSelfDeclaration: number,welcomeMsg: string,welcomeMsgErrorCode: number) {
      this.haveVaxSelfDeclaration=haveVaxSelfDeclaration;
      this.welcomeMsg=welcomeMsg;
      this.welcomeMsgErrorCode=welcomeMsgErrorCode;
    }   
  }