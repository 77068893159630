import { BrowserModule,Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import 'isomorphic-fetch';
import { RebarAuthModule } from './core/rebarauth/rebar.auth.module';
import { AppConfigService } from './core/services/app-config.service';
import { APP_INITIALIZER } from '@angular/core'; // for IE issue
import { BaseService } from './core/services/base.service';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner"; 
import { CustomToastComponent } from './shared/custom-toast/custom-toast.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';


@NgModule({
  declarations: [
    AppComponent,
    CustomToastComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
      toastComponent: CustomToastComponent
    }),
    RebarAuthModule.forRoot(),
    NgxSpinnerModule
  ],
  providers: [
    Title,
    BaseService,
    DatePipe,
    NgxSpinnerService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService) => () => config.load(),
      deps: [AppConfigService], multi: true
  }
  ],
  entryComponents: [CustomToastComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
