import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';

import {
  MsalModule,
  MsalInterceptor,
  MsalGuard,
  MSAL_CONFIG,
  MsalService,
  MSAL_CONFIG_ANGULAR,
  MsalAngularConfiguration,
} from '@azure/msal-angular';
import { AppConfigService } from '../services/app-config.service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogLevel, Configuration } from 'msal';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';

export const REBAR_AUTH_GUARD = new InjectionToken<string>('REBAR_AUTH_GUARD');

/*
  When running locally `npm run start:local` or `npm run test` or `npm run e2e`
  use mock values, to turn off security.  This is set at build time.
*/

export const PROVIDERS = {
  mock: [
    {
      provide: MSAL_CONFIG,
      useValue: { client: 'mock', authority: 'mock' },
    },
    {
      provide: REBAR_AUTH_GUARD,
      useValue: emptyGuard,
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useValue: {},
    },
    MsalService,
  ],
  app: [
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory,
      deps: [AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory,
      deps: [AppConfigService],
    },
    MsalService,
    { provide: REBAR_AUTH_GUARD, useClass: MsalGuard },
  ],
};

@NgModule({
  imports: [MsalModule],
})
export class RebarAuthModule {
  static forRoot(): ModuleWithProviders<RebarAuthModule> {
    return {
      ngModule: RebarAuthModule,
      providers: PROVIDERS[environment.providers],
    };
  }
}

export function MSALAngularConfigFactory(
  config: AppConfigService
): MsalAngularConfiguration {
  const msal = config.config.msal;
  let auth;
  let framework;

  if (msal) {
    auth = msal.auth;
  }
  if (auth) {
    framework = auth.framework;
  }

  return framework || {};
}

export function MSALConfigFactory(config: AppConfigService): Configuration {
  const cfg = Object.assign({}, config.config.msal, {
    level: LogLevel.Verbose,
    piiLoggingEnabled: false,
  });
  return cfg;
}

export function emptyGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  return true;
}
