import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout,take } from 'rxjs/operators';
import { BaseService } from '../../../core/services/base.service';
import { AppConfigService } from '../../../core/services/app-config.service';
import { UpdateSelfDeclarationVaxStatus } from '../../../interface/IUpdateSelfDeclarationVaxStatus';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  serivceUrls: any = null;
  configData : any;
  constructor(private baseService: BaseService, private appConfig: AppConfigService) {
		this.serivceUrls = this.appConfig.getServiceUrls();
	}
public LoggedInUser:any;
public ResourceId:any;
  public getResources(enterpriseId): Observable<any> {
    return this.baseService.get(this.serivceUrls.hostEndpoint.AccountServices + this.serivceUrls.serviceEndpoint.AccountServices.GetResources + '?EnterpriseId=' + enterpriseId).pipe(timeout(180000));
  }

   public async getResourceFacCD(enterpriseId: string): Promise<any>{
    return await this.baseService.get(this.serivceUrls.hostEndpoint.AccountServices + this.serivceUrls.serviceEndpoint.AccountServices.getResourceFacCD + '?EnterpriseId=' + enterpriseId).pipe(timeout(180000)).toPromise(); 
   }

  public async getResourceId(enterpriseId): Promise<any> {
    return await this.baseService.get(this.serivceUrls.hostEndpoint.AccountServices + this.serivceUrls.serviceEndpoint.AccountServices.GetResourcesDetails + '?EnterpriseId=' + enterpriseId).pipe(timeout(180000)).toPromise();
  }
  public async GetSelfDeclarationDetails(resourceIds: any):Promise<any> 
  {
    this.configData = this.appConfig.getConfig();
    return await this.baseService.get(this.serivceUrls.hostEndpoint.AccountServices
      + this.serivceUrls.serviceEndpoint.AccountServices.GetSelfDeclarationDetails + "?ResourceId=" + resourceIds).pipe(timeout(this.configData.apiTimeout)).toPromise();
  }
  updateDemand={} 
  public async GetSelfDeclarationVaxStatus(resourceId: any) {
    this.configData = this.appConfig.getConfig();
    let selfdeclarationDetails = await this.GetSelfDeclarationDetails(resourceId);
    if (this.configData.SelfDeclarationFlag == true && this.configData.VaccinationFlag == true) {
      if (selfdeclarationDetails[0].VaxStatus !== 1 && selfdeclarationDetails[0].EmployeeAcknowledgement !== 1) {
        return new UpdateSelfDeclarationVaxStatus(1,this.configData.EmployeeAcknowledgementMsg,1); 
      } else {
        if (selfdeclarationDetails[0].VaxStatus !== 1) {
          return new UpdateSelfDeclarationVaxStatus(1,this.configData.EmployeeVaxAcknowledgementMsg,2);
        }
        if (selfdeclarationDetails[0].EmployeeAcknowledgement !== 1) {
          return new UpdateSelfDeclarationVaxStatus(1,this.configData.EmployeeSelfAcknowledgementMsg,3);
        }
      }
    } else {
      if (this.configData.VaccinationFlag == true) {
        if (selfdeclarationDetails[0].VaxStatus !== 1) {
          return new UpdateSelfDeclarationVaxStatus(1,this.configData.EmployeeVaxAcknowledgementMsg,2)
        }
      }
      if (this.configData.SelfDeclarationFlag == true) {
        if (selfdeclarationDetails[0].EmployeeAcknowledgement !== 1) {
          return new UpdateSelfDeclarationVaxStatus(1,this.configData.EmployeeSelfAcknowledgementMsg,3)
        }
      }
    }
  } 
   
  public GetResourcesDetailsForHoteling(enterpriseId): Observable<any> {
    return this.baseService.get(this.serivceUrls.hostEndpoint.AccountServices + this.serivceUrls.serviceEndpoint.AccountServices.GetResourcesDetailsForHoteling + '?EnterpriseId=' + enterpriseId);
  }

  public  GetSelfDeclarationVaxStatusDetails(EmployeeAcknowledgement,VaxStatus) {
    this.configData = this.appConfig.getConfig();
    let configDBData = sessionStorage.getItem("configDBData");
    this.configData.SelfDeclarationFlag = (JSON.parse(configDBData))[0].selfdeclaationcheck;
    this.configData.VaccinationFlag = (JSON.parse(configDBData))[0].vaccinationcheck;
    if (this.configData.SelfDeclarationFlag == true && this.configData.VaccinationFlag == true) {
      if (VaxStatus !== 1 && EmployeeAcknowledgement !== 1) {
        return new UpdateSelfDeclarationVaxStatus(1,this.configData.EmployeeAcknowledgementMsg,1); 
      } else {
        if (VaxStatus !== 1) {
          return new UpdateSelfDeclarationVaxStatus(1,this.configData.EmployeeVaxAcknowledgementMsg,2);
        }
        if (EmployeeAcknowledgement !== 1) {
          return new UpdateSelfDeclarationVaxStatus(1,this.configData.EmployeeSelfAcknowledgementMsg,3);
        }
      }
    } else {
      if (this.configData.VaccinationFlag == true) {
        if (VaxStatus !== 1) {
          return new UpdateSelfDeclarationVaxStatus(1,this.configData.EmployeeVaxAcknowledgementMsg,2)
        }
      }
      if (this.configData.SelfDeclarationFlag == true) {
        if (EmployeeAcknowledgement !== 1) {
          return new UpdateSelfDeclarationVaxStatus(1,this.configData.EmployeeSelfAcknowledgementMsg,3)
        }
      }
    }
  }
  
  public  GetConfigValuesFromDB(entity): Observable<any>  {
    return  this.baseService.get(this.serivceUrls.hostEndpoint.MasterServices
      + this.serivceUrls.serviceEndpoint.MasterServices.GetSeatManagementConfig + "?entity=" + entity).pipe(timeout(180000));
  }
}
